
// @ts-nocheck


export const localeCodes =  [
  "en",
  "en-be",
  "en-bo",
  "es-bo",
  "en-bz",
  "es-bz",
  "en-ca",
  "en-cl",
  "es-cl",
  "en-co",
  "es-co",
  "en-cr",
  "es-cr",
  "en-ec",
  "es-ec",
  "en-es",
  "es",
  "es-es",
  "en-ge",
  "en-gb",
  "en-hk",
  "en-hu",
  "hu-hu",
  "en-in",
  "en-au",
  "en-mx",
  "es-mx",
  "en-ni",
  "es-ni",
  "en-nl",
  "en-pa",
  "es-pa",
  "en-pe",
  "es-pe",
  "en-pl",
  "pl-pl",
  "en-pr",
  "es-pr",
  "en-pt",
  "pt-pt",
  "en-py",
  "es-py",
  "en-ro",
  "ro-ro",
  "en-za"
]

export const localeMessages = {
  "en": [{ key: "../lang/en.json", load: () => import("../lang/en.json" /* webpackChunkName: "locale__data_lang_en_json" */), cache: true },
{ key: "../lang/en-GB.json", load: () => import("../lang/en-GB.json" /* webpackChunkName: "locale__data_lang_en_GB_json" */), cache: false }],
  "en-be": [{ key: "../lang/en.json", load: () => import("../lang/en.json" /* webpackChunkName: "locale__data_lang_en_json" */), cache: true },
{ key: "../lang/en-BE.json", load: () => import("../lang/en-BE.json" /* webpackChunkName: "locale__data_lang_en_BE_json" */), cache: false }],
  "en-bo": [{ key: "../lang/en.json", load: () => import("../lang/en.json" /* webpackChunkName: "locale__data_lang_en_json" */), cache: true },
{ key: "../lang/en-BO.json", load: () => import("../lang/en-BO.json" /* webpackChunkName: "locale__data_lang_en_BO_json" */), cache: false }],
  "es-bo": [{ key: "../lang/es.json", load: () => import("../lang/es.json" /* webpackChunkName: "locale__data_lang_es_json" */), cache: true },
{ key: "../lang/es-BO.json", load: () => import("../lang/es-BO.json" /* webpackChunkName: "locale__data_lang_es_BO_json" */), cache: false }],
  "en-bz": [{ key: "../lang/en.json", load: () => import("../lang/en.json" /* webpackChunkName: "locale__data_lang_en_json" */), cache: true },
{ key: "../lang/en-BZ.json", load: () => import("../lang/en-BZ.json" /* webpackChunkName: "locale__data_lang_en_BZ_json" */), cache: false }],
  "es-bz": [{ key: "../lang/es.json", load: () => import("../lang/es.json" /* webpackChunkName: "locale__data_lang_es_json" */), cache: true },
{ key: "../lang/es-BZ.json", load: () => import("../lang/es-BZ.json" /* webpackChunkName: "locale__data_lang_es_BZ_json" */), cache: false }],
  "en-ca": [{ key: "../lang/en.json", load: () => import("../lang/en.json" /* webpackChunkName: "locale__data_lang_en_json" */), cache: true },
{ key: "../lang/en-CA.json", load: () => import("../lang/en-CA.json" /* webpackChunkName: "locale__data_lang_en_CA_json" */), cache: false }],
  "en-cl": [{ key: "../lang/en.json", load: () => import("../lang/en.json" /* webpackChunkName: "locale__data_lang_en_json" */), cache: true },
{ key: "../lang/en-CL.json", load: () => import("../lang/en-CL.json" /* webpackChunkName: "locale__data_lang_en_CL_json" */), cache: false }],
  "es-cl": [{ key: "../lang/es.json", load: () => import("../lang/es.json" /* webpackChunkName: "locale__data_lang_es_json" */), cache: true },
{ key: "../lang/es-CL.json", load: () => import("../lang/es-CL.json" /* webpackChunkName: "locale__data_lang_es_CL_json" */), cache: false }],
  "en-co": [{ key: "../lang/en.json", load: () => import("../lang/en.json" /* webpackChunkName: "locale__data_lang_en_json" */), cache: true },
{ key: "../lang/en-CO.json", load: () => import("../lang/en-CO.json" /* webpackChunkName: "locale__data_lang_en_CO_json" */), cache: false }],
  "es-co": [{ key: "../lang/es.json", load: () => import("../lang/es.json" /* webpackChunkName: "locale__data_lang_es_json" */), cache: true },
{ key: "../lang/es-CO.json", load: () => import("../lang/es-CO.json" /* webpackChunkName: "locale__data_lang_es_CO_json" */), cache: false }],
  "en-cr": [{ key: "../lang/en.json", load: () => import("../lang/en.json" /* webpackChunkName: "locale__data_lang_en_json" */), cache: true },
{ key: "../lang/en-CR.json", load: () => import("../lang/en-CR.json" /* webpackChunkName: "locale__data_lang_en_CR_json" */), cache: false }],
  "es-cr": [{ key: "../lang/es.json", load: () => import("../lang/es.json" /* webpackChunkName: "locale__data_lang_es_json" */), cache: true },
{ key: "../lang/es-CR.json", load: () => import("../lang/es-CR.json" /* webpackChunkName: "locale__data_lang_es_CR_json" */), cache: false }],
  "en-ec": [{ key: "../lang/en.json", load: () => import("../lang/en.json" /* webpackChunkName: "locale__data_lang_en_json" */), cache: true },
{ key: "../lang/en-EC.json", load: () => import("../lang/en-EC.json" /* webpackChunkName: "locale__data_lang_en_EC_json" */), cache: false }],
  "es-ec": [{ key: "../lang/es.json", load: () => import("../lang/es.json" /* webpackChunkName: "locale__data_lang_es_json" */), cache: true },
{ key: "../lang/es-EC.json", load: () => import("../lang/es-EC.json" /* webpackChunkName: "locale__data_lang_es_EC_json" */), cache: false }],
  "en-es": [{ key: "../lang/en.json", load: () => import("../lang/en.json" /* webpackChunkName: "locale__data_lang_en_json" */), cache: true },
{ key: "../lang/en-ES.json", load: () => import("../lang/en-ES.json" /* webpackChunkName: "locale__data_lang_en_ES_json" */), cache: false }],
  "es": [{ key: "../lang/es.json", load: () => import("../lang/es.json" /* webpackChunkName: "locale__data_lang_es_json" */), cache: true }],
  "es-es": [{ key: "../lang/es.json", load: () => import("../lang/es.json" /* webpackChunkName: "locale__data_lang_es_json" */), cache: true }],
  "en-ge": [{ key: "../lang/en.json", load: () => import("../lang/en.json" /* webpackChunkName: "locale__data_lang_en_json" */), cache: true },
{ key: "../lang/en-GE.json", load: () => import("../lang/en-GE.json" /* webpackChunkName: "locale__data_lang_en_GE_json" */), cache: false }],
  "en-gb": [{ key: "../lang/en.json", load: () => import("../lang/en.json" /* webpackChunkName: "locale__data_lang_en_json" */), cache: true },
{ key: "../lang/en-GB.json", load: () => import("../lang/en-GB.json" /* webpackChunkName: "locale__data_lang_en_GB_json" */), cache: false }],
  "en-hk": [{ key: "../lang/en.json", load: () => import("../lang/en.json" /* webpackChunkName: "locale__data_lang_en_json" */), cache: true },
{ key: "../lang/en-HK.json", load: () => import("../lang/en-HK.json" /* webpackChunkName: "locale__data_lang_en_HK_json" */), cache: false }],
  "en-hu": [{ key: "../lang/en.json", load: () => import("../lang/en.json" /* webpackChunkName: "locale__data_lang_en_json" */), cache: true },
{ key: "../lang/en-HU.json", load: () => import("../lang/en-HU.json" /* webpackChunkName: "locale__data_lang_en_HU_json" */), cache: false }],
  "hu-hu": [{ key: "../lang/hu.json", load: () => import("../lang/hu.json" /* webpackChunkName: "locale__data_lang_hu_json" */), cache: true }],
  "en-in": [{ key: "../lang/en.json", load: () => import("../lang/en.json" /* webpackChunkName: "locale__data_lang_en_json" */), cache: true },
{ key: "../lang/en-IN.json", load: () => import("../lang/en-IN.json" /* webpackChunkName: "locale__data_lang_en_IN_json" */), cache: false }],
  "en-au": [{ key: "../lang/en.json", load: () => import("../lang/en.json" /* webpackChunkName: "locale__data_lang_en_json" */), cache: true },
{ key: "../lang/en-AU.json", load: () => import("../lang/en-AU.json" /* webpackChunkName: "locale__data_lang_en_AU_json" */), cache: false }],
  "en-mx": [{ key: "../lang/en.json", load: () => import("../lang/en.json" /* webpackChunkName: "locale__data_lang_en_json" */), cache: true },
{ key: "../lang/en-MX.json", load: () => import("../lang/en-MX.json" /* webpackChunkName: "locale__data_lang_en_MX_json" */), cache: false }],
  "es-mx": [{ key: "../lang/es.json", load: () => import("../lang/es.json" /* webpackChunkName: "locale__data_lang_es_json" */), cache: true },
{ key: "../lang/es-MX.json", load: () => import("../lang/es-MX.json" /* webpackChunkName: "locale__data_lang_es_MX_json" */), cache: false }],
  "en-ni": [{ key: "../lang/en.json", load: () => import("../lang/en.json" /* webpackChunkName: "locale__data_lang_en_json" */), cache: true },
{ key: "../lang/en-NI.json", load: () => import("../lang/en-NI.json" /* webpackChunkName: "locale__data_lang_en_NI_json" */), cache: false }],
  "es-ni": [{ key: "../lang/es.json", load: () => import("../lang/es.json" /* webpackChunkName: "locale__data_lang_es_json" */), cache: true },
{ key: "../lang/es-NI.json", load: () => import("../lang/es-NI.json" /* webpackChunkName: "locale__data_lang_es_NI_json" */), cache: false }],
  "en-nl": [{ key: "../lang/en.json", load: () => import("../lang/en.json" /* webpackChunkName: "locale__data_lang_en_json" */), cache: true },
{ key: "../lang/en-NL.json", load: () => import("../lang/en-NL.json" /* webpackChunkName: "locale__data_lang_en_NL_json" */), cache: false }],
  "en-pa": [{ key: "../lang/en.json", load: () => import("../lang/en.json" /* webpackChunkName: "locale__data_lang_en_json" */), cache: true },
{ key: "../lang/en-PA.json", load: () => import("../lang/en-PA.json" /* webpackChunkName: "locale__data_lang_en_PA_json" */), cache: false }],
  "es-pa": [{ key: "../lang/es.json", load: () => import("../lang/es.json" /* webpackChunkName: "locale__data_lang_es_json" */), cache: true },
{ key: "../lang/es-PA.json", load: () => import("../lang/es-PA.json" /* webpackChunkName: "locale__data_lang_es_PA_json" */), cache: false }],
  "en-pe": [{ key: "../lang/en.json", load: () => import("../lang/en.json" /* webpackChunkName: "locale__data_lang_en_json" */), cache: true },
{ key: "../lang/en-PE.json", load: () => import("../lang/en-PE.json" /* webpackChunkName: "locale__data_lang_en_PE_json" */), cache: false }],
  "es-pe": [{ key: "../lang/es.json", load: () => import("../lang/es.json" /* webpackChunkName: "locale__data_lang_es_json" */), cache: true },
{ key: "../lang/es-PE.json", load: () => import("../lang/es-PE.json" /* webpackChunkName: "locale__data_lang_es_PE_json" */), cache: false }],
  "en-pl": [{ key: "../lang/en.json", load: () => import("../lang/en.json" /* webpackChunkName: "locale__data_lang_en_json" */), cache: true },
{ key: "../lang/en-PL.json", load: () => import("../lang/en-PL.json" /* webpackChunkName: "locale__data_lang_en_PL_json" */), cache: false }],
  "pl-pl": [{ key: "../lang/pl.json", load: () => import("../lang/pl.json" /* webpackChunkName: "locale__data_lang_pl_json" */), cache: true }],
  "en-pr": [{ key: "../lang/en.json", load: () => import("../lang/en.json" /* webpackChunkName: "locale__data_lang_en_json" */), cache: true },
{ key: "../lang/en-PR.json", load: () => import("../lang/en-PR.json" /* webpackChunkName: "locale__data_lang_en_PR_json" */), cache: false }],
  "es-pr": [{ key: "../lang/es.json", load: () => import("../lang/es.json" /* webpackChunkName: "locale__data_lang_es_json" */), cache: true },
{ key: "../lang/es-PR.json", load: () => import("../lang/es-PR.json" /* webpackChunkName: "locale__data_lang_es_PR_json" */), cache: false }],
  "en-pt": [{ key: "../lang/en.json", load: () => import("../lang/en.json" /* webpackChunkName: "locale__data_lang_en_json" */), cache: true },
{ key: "../lang/en-PT.json", load: () => import("../lang/en-PT.json" /* webpackChunkName: "locale__data_lang_en_PT_json" */), cache: false }],
  "pt-pt": [{ key: "../lang/pt.json", load: () => import("../lang/pt.json" /* webpackChunkName: "locale__data_lang_pt_json" */), cache: true }],
  "en-py": [{ key: "../lang/en.json", load: () => import("../lang/en.json" /* webpackChunkName: "locale__data_lang_en_json" */), cache: true },
{ key: "../lang/en-PY.json", load: () => import("../lang/en-PY.json" /* webpackChunkName: "locale__data_lang_en_PY_json" */), cache: false }],
  "es-py": [{ key: "../lang/es.json", load: () => import("../lang/es.json" /* webpackChunkName: "locale__data_lang_es_json" */), cache: true },
{ key: "../lang/es-PY.json", load: () => import("../lang/es-PY.json" /* webpackChunkName: "locale__data_lang_es_PY_json" */), cache: false }],
  "en-ro": [{ key: "../lang/en.json", load: () => import("../lang/en.json" /* webpackChunkName: "locale__data_lang_en_json" */), cache: true },
{ key: "../lang/en-RO.json", load: () => import("../lang/en-RO.json" /* webpackChunkName: "locale__data_lang_en_RO_json" */), cache: false }],
  "ro-ro": [{ key: "../lang/ro.json", load: () => import("../lang/ro.json" /* webpackChunkName: "locale__data_lang_ro_json" */), cache: true },
{ key: "../lang/ro.json", load: () => import("../lang/ro.json" /* webpackChunkName: "locale__data_lang_ro_json" */), cache: true }],
  "en-za": [{ key: "../lang/en.json", load: () => import("../lang/en.json" /* webpackChunkName: "locale__data_lang_en_json" */), cache: true },
{ key: "../lang/en-ZA.json", load: () => import("../lang/en-ZA.json" /* webpackChunkName: "locale__data_lang_en_ZA_json" */), cache: false }]
}

export const vueI18nConfigs = [
  () => import("../i18n.config.ts?hash=bffaebcb&config=1" /* webpackChunkName: "__i18n_config_ts_bffaebcb" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": ""
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": false,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./i18n.config.ts",
  "locales": [
    {
      "code": "en",
      "iso": "en",
      "files": [
        "lang/en.json",
        "lang/en-GB.json"
      ]
    },
    {
      "code": "en-be",
      "iso": "en-be",
      "files": [
        "lang/en.json",
        "lang/en-BE.json"
      ]
    },
    {
      "code": "en-bo",
      "iso": "en-bo",
      "files": [
        "lang/en.json",
        "lang/en-BO.json"
      ]
    },
    {
      "code": "es-bo",
      "iso": "es-bo",
      "files": [
        "lang/es.json",
        "lang/es-BO.json"
      ]
    },
    {
      "code": "en-bz",
      "iso": "en-bz",
      "files": [
        "lang/en.json",
        "lang/en-BZ.json"
      ]
    },
    {
      "code": "es-bz",
      "iso": "es-bz",
      "files": [
        "lang/es.json",
        "lang/es-BZ.json"
      ]
    },
    {
      "code": "en-ca",
      "iso": "en-ca",
      "files": [
        "lang/en.json",
        "lang/en-CA.json"
      ]
    },
    {
      "code": "en-cl",
      "iso": "en-cl",
      "files": [
        "lang/en.json",
        "lang/en-CL.json"
      ]
    },
    {
      "code": "es-cl",
      "iso": "es-cl",
      "files": [
        "lang/es.json",
        "lang/es-CL.json"
      ]
    },
    {
      "code": "en-co",
      "iso": "en-co",
      "files": [
        "lang/en.json",
        "lang/en-CO.json"
      ]
    },
    {
      "code": "es-co",
      "iso": "es-co",
      "files": [
        "lang/es.json",
        "lang/es-CO.json"
      ]
    },
    {
      "code": "en-cr",
      "iso": "en-cr",
      "files": [
        "lang/en.json",
        "lang/en-CR.json"
      ]
    },
    {
      "code": "es-cr",
      "iso": "es-cr",
      "files": [
        "lang/es.json",
        "lang/es-CR.json"
      ]
    },
    {
      "code": "en-ec",
      "iso": "en-ec",
      "files": [
        "lang/en.json",
        "lang/en-EC.json"
      ]
    },
    {
      "code": "es-ec",
      "iso": "es-ec",
      "files": [
        "lang/es.json",
        "lang/es-EC.json"
      ]
    },
    {
      "code": "en-es",
      "iso": "en-es",
      "files": [
        "lang/en.json",
        "lang/en-ES.json"
      ]
    },
    {
      "code": "es",
      "iso": "es",
      "files": [
        "lang/es.json"
      ]
    },
    {
      "code": "es-es",
      "iso": "es-es",
      "files": [
        "lang/es.json"
      ]
    },
    {
      "code": "en-ge",
      "iso": "en-ge",
      "files": [
        "lang/en.json",
        "lang/en-GE.json"
      ]
    },
    {
      "code": "en-gb",
      "iso": "en-gb",
      "files": [
        "lang/en.json",
        "lang/en-GB.json"
      ]
    },
    {
      "code": "en-hk",
      "iso": "en-hk",
      "files": [
        "lang/en.json",
        "lang/en-HK.json"
      ]
    },
    {
      "code": "en-hu",
      "iso": "en-hu",
      "files": [
        "lang/en.json",
        "lang/en-HU.json"
      ]
    },
    {
      "code": "hu-hu",
      "iso": "hu-hu",
      "files": [
        "lang/hu.json"
      ]
    },
    {
      "code": "en-in",
      "iso": "en-in",
      "files": [
        "lang/en.json",
        "lang/en-IN.json"
      ]
    },
    {
      "code": "en-au",
      "iso": "en-au",
      "files": [
        "lang/en.json",
        "lang/en-AU.json"
      ]
    },
    {
      "code": "en-mx",
      "iso": "en-mx",
      "files": [
        "lang/en.json",
        "lang/en-MX.json"
      ]
    },
    {
      "code": "es-mx",
      "iso": "es-mx",
      "files": [
        "lang/es.json",
        "lang/es-MX.json"
      ]
    },
    {
      "code": "en-ni",
      "iso": "en-ni",
      "files": [
        "lang/en.json",
        "lang/en-NI.json"
      ]
    },
    {
      "code": "es-ni",
      "iso": "es-ni",
      "files": [
        "lang/es.json",
        "lang/es-NI.json"
      ]
    },
    {
      "code": "en-nl",
      "iso": "en-nl",
      "files": [
        "lang/en.json",
        "lang/en-NL.json"
      ]
    },
    {
      "code": "en-pa",
      "iso": "en-pa",
      "files": [
        "lang/en.json",
        "lang/en-PA.json"
      ]
    },
    {
      "code": "es-pa",
      "iso": "es-pa",
      "files": [
        "lang/es.json",
        "lang/es-PA.json"
      ]
    },
    {
      "code": "en-pe",
      "iso": "en-pe",
      "files": [
        "lang/en.json",
        "lang/en-PE.json"
      ]
    },
    {
      "code": "es-pe",
      "iso": "es-pe",
      "files": [
        "lang/es.json",
        "lang/es-PE.json"
      ]
    },
    {
      "code": "en-pl",
      "iso": "en-pl",
      "files": [
        "lang/en.json",
        "lang/en-PL.json"
      ]
    },
    {
      "code": "pl-pl",
      "iso": "pl-pl",
      "files": [
        "lang/pl.json"
      ]
    },
    {
      "code": "en-pr",
      "iso": "en-pr",
      "files": [
        "lang/en.json",
        "lang/en-PR.json"
      ]
    },
    {
      "code": "es-pr",
      "iso": "es-pr",
      "files": [
        "lang/es.json",
        "lang/es-PR.json"
      ]
    },
    {
      "code": "en-pt",
      "iso": "en-pt",
      "files": [
        "lang/en.json",
        "lang/en-PT.json"
      ]
    },
    {
      "code": "pt-pt",
      "iso": "pt-pt",
      "files": [
        "lang/pt.json"
      ]
    },
    {
      "code": "en-py",
      "iso": "en-py",
      "files": [
        "lang/en.json",
        "lang/en-PY.json"
      ]
    },
    {
      "code": "es-py",
      "iso": "es-py",
      "files": [
        "lang/es.json",
        "lang/es-PY.json"
      ]
    },
    {
      "code": "en-ro",
      "iso": "en-ro",
      "files": [
        "lang/en.json",
        "lang/en-RO.json"
      ]
    },
    {
      "code": "ro-ro",
      "iso": "ro-ro",
      "files": [
        "lang/ro.json",
        "lang/ro.json"
      ]
    },
    {
      "code": "en-za",
      "iso": "en-za",
      "files": [
        "lang/en.json",
        "lang/en-ZA.json"
      ]
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix",
  "lazy": true,
  "langDir": "lang",
  "rootRedirect": null,
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const nuxtI18nOptionsDefault = {
  "experimental": {
    "localeDetector": ""
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [],
  "defaultLocale": "",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": null,
  "rootRedirect": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false
}

export const nuxtI18nInternalOptions = {
  "__normalizedLocales": [
    {
      "code": "en",
      "iso": "en",
      "files": [
        {
          "path": "lang/en.json"
        },
        {
          "path": "lang/en-GB.json",
          "cache": false
        }
      ]
    },
    {
      "code": "en-be",
      "iso": "en-be",
      "files": [
        {
          "path": "lang/en.json"
        },
        {
          "path": "lang/en-BE.json",
          "cache": false
        }
      ]
    },
    {
      "code": "en-bo",
      "iso": "en-bo",
      "files": [
        {
          "path": "lang/en.json"
        },
        {
          "path": "lang/en-BO.json",
          "cache": false
        }
      ]
    },
    {
      "code": "es-bo",
      "iso": "es-bo",
      "files": [
        {
          "path": "lang/es.json"
        },
        {
          "path": "lang/es-BO.json",
          "cache": false
        }
      ]
    },
    {
      "code": "en-bz",
      "iso": "en-bz",
      "files": [
        {
          "path": "lang/en.json"
        },
        {
          "path": "lang/en-BZ.json",
          "cache": false
        }
      ]
    },
    {
      "code": "es-bz",
      "iso": "es-bz",
      "files": [
        {
          "path": "lang/es.json"
        },
        {
          "path": "lang/es-BZ.json",
          "cache": false
        }
      ]
    },
    {
      "code": "en-ca",
      "iso": "en-ca",
      "files": [
        {
          "path": "lang/en.json"
        },
        {
          "path": "lang/en-CA.json",
          "cache": false
        }
      ]
    },
    {
      "code": "en-cl",
      "iso": "en-cl",
      "files": [
        {
          "path": "lang/en.json"
        },
        {
          "path": "lang/en-CL.json",
          "cache": false
        }
      ]
    },
    {
      "code": "es-cl",
      "iso": "es-cl",
      "files": [
        {
          "path": "lang/es.json"
        },
        {
          "path": "lang/es-CL.json",
          "cache": false
        }
      ]
    },
    {
      "code": "en-co",
      "iso": "en-co",
      "files": [
        {
          "path": "lang/en.json"
        },
        {
          "path": "lang/en-CO.json",
          "cache": false
        }
      ]
    },
    {
      "code": "es-co",
      "iso": "es-co",
      "files": [
        {
          "path": "lang/es.json"
        },
        {
          "path": "lang/es-CO.json",
          "cache": false
        }
      ]
    },
    {
      "code": "en-cr",
      "iso": "en-cr",
      "files": [
        {
          "path": "lang/en.json"
        },
        {
          "path": "lang/en-CR.json",
          "cache": false
        }
      ]
    },
    {
      "code": "es-cr",
      "iso": "es-cr",
      "files": [
        {
          "path": "lang/es.json"
        },
        {
          "path": "lang/es-CR.json",
          "cache": false
        }
      ]
    },
    {
      "code": "en-ec",
      "iso": "en-ec",
      "files": [
        {
          "path": "lang/en.json"
        },
        {
          "path": "lang/en-EC.json",
          "cache": false
        }
      ]
    },
    {
      "code": "es-ec",
      "iso": "es-ec",
      "files": [
        {
          "path": "lang/es.json"
        },
        {
          "path": "lang/es-EC.json",
          "cache": false
        }
      ]
    },
    {
      "code": "en-es",
      "iso": "en-es",
      "files": [
        {
          "path": "lang/en.json"
        },
        {
          "path": "lang/en-ES.json",
          "cache": false
        }
      ]
    },
    {
      "code": "es",
      "iso": "es",
      "files": [
        {
          "path": "lang/es.json"
        }
      ]
    },
    {
      "code": "es-es",
      "iso": "es-es",
      "files": [
        {
          "path": "lang/es.json"
        }
      ]
    },
    {
      "code": "en-ge",
      "iso": "en-ge",
      "files": [
        {
          "path": "lang/en.json"
        },
        {
          "path": "lang/en-GE.json",
          "cache": false
        }
      ]
    },
    {
      "code": "en-gb",
      "iso": "en-gb",
      "files": [
        {
          "path": "lang/en.json"
        },
        {
          "path": "lang/en-GB.json",
          "cache": false
        }
      ]
    },
    {
      "code": "en-hk",
      "iso": "en-hk",
      "files": [
        {
          "path": "lang/en.json"
        },
        {
          "path": "lang/en-HK.json",
          "cache": false
        }
      ]
    },
    {
      "code": "en-hu",
      "iso": "en-hu",
      "files": [
        {
          "path": "lang/en.json"
        },
        {
          "path": "lang/en-HU.json",
          "cache": false
        }
      ]
    },
    {
      "code": "hu-hu",
      "iso": "hu-hu",
      "files": [
        {
          "path": "lang/hu.json"
        }
      ]
    },
    {
      "code": "en-in",
      "iso": "en-in",
      "files": [
        {
          "path": "lang/en.json"
        },
        {
          "path": "lang/en-IN.json",
          "cache": false
        }
      ]
    },
    {
      "code": "en-au",
      "iso": "en-au",
      "files": [
        {
          "path": "lang/en.json"
        },
        {
          "path": "lang/en-AU.json",
          "cache": false
        }
      ]
    },
    {
      "code": "en-mx",
      "iso": "en-mx",
      "files": [
        {
          "path": "lang/en.json"
        },
        {
          "path": "lang/en-MX.json",
          "cache": false
        }
      ]
    },
    {
      "code": "es-mx",
      "iso": "es-mx",
      "files": [
        {
          "path": "lang/es.json"
        },
        {
          "path": "lang/es-MX.json",
          "cache": false
        }
      ]
    },
    {
      "code": "en-ni",
      "iso": "en-ni",
      "files": [
        {
          "path": "lang/en.json"
        },
        {
          "path": "lang/en-NI.json",
          "cache": false
        }
      ]
    },
    {
      "code": "es-ni",
      "iso": "es-ni",
      "files": [
        {
          "path": "lang/es.json"
        },
        {
          "path": "lang/es-NI.json",
          "cache": false
        }
      ]
    },
    {
      "code": "en-nl",
      "iso": "en-nl",
      "files": [
        {
          "path": "lang/en.json"
        },
        {
          "path": "lang/en-NL.json",
          "cache": false
        }
      ]
    },
    {
      "code": "en-pa",
      "iso": "en-pa",
      "files": [
        {
          "path": "lang/en.json"
        },
        {
          "path": "lang/en-PA.json",
          "cache": false
        }
      ]
    },
    {
      "code": "es-pa",
      "iso": "es-pa",
      "files": [
        {
          "path": "lang/es.json"
        },
        {
          "path": "lang/es-PA.json",
          "cache": false
        }
      ]
    },
    {
      "code": "en-pe",
      "iso": "en-pe",
      "files": [
        {
          "path": "lang/en.json"
        },
        {
          "path": "lang/en-PE.json",
          "cache": false
        }
      ]
    },
    {
      "code": "es-pe",
      "iso": "es-pe",
      "files": [
        {
          "path": "lang/es.json"
        },
        {
          "path": "lang/es-PE.json",
          "cache": false
        }
      ]
    },
    {
      "code": "en-pl",
      "iso": "en-pl",
      "files": [
        {
          "path": "lang/en.json"
        },
        {
          "path": "lang/en-PL.json",
          "cache": false
        }
      ]
    },
    {
      "code": "pl-pl",
      "iso": "pl-pl",
      "files": [
        {
          "path": "lang/pl.json"
        }
      ]
    },
    {
      "code": "en-pr",
      "iso": "en-pr",
      "files": [
        {
          "path": "lang/en.json"
        },
        {
          "path": "lang/en-PR.json",
          "cache": false
        }
      ]
    },
    {
      "code": "es-pr",
      "iso": "es-pr",
      "files": [
        {
          "path": "lang/es.json"
        },
        {
          "path": "lang/es-PR.json",
          "cache": false
        }
      ]
    },
    {
      "code": "en-pt",
      "iso": "en-pt",
      "files": [
        {
          "path": "lang/en.json"
        },
        {
          "path": "lang/en-PT.json",
          "cache": false
        }
      ]
    },
    {
      "code": "pt-pt",
      "iso": "pt-pt",
      "files": [
        {
          "path": "lang/pt.json"
        }
      ]
    },
    {
      "code": "en-py",
      "iso": "en-py",
      "files": [
        {
          "path": "lang/en.json"
        },
        {
          "path": "lang/en-PY.json",
          "cache": false
        }
      ]
    },
    {
      "code": "es-py",
      "iso": "es-py",
      "files": [
        {
          "path": "lang/es.json"
        },
        {
          "path": "lang/es-PY.json",
          "cache": false
        }
      ]
    },
    {
      "code": "en-ro",
      "iso": "en-ro",
      "files": [
        {
          "path": "lang/en.json"
        },
        {
          "path": "lang/en-RO.json",
          "cache": false
        }
      ]
    },
    {
      "code": "ro-ro",
      "iso": "ro-ro",
      "files": [
        {
          "path": "lang/ro.json"
        },
        {
          "path": "lang/ro.json",
          "cache": false
        }
      ]
    },
    {
      "code": "en-za",
      "iso": "en-za",
      "files": [
        {
          "path": "lang/en.json"
        },
        {
          "path": "lang/en-ZA.json",
          "cache": false
        }
      ]
    }
  ]
}

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

