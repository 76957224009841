<template>
  <div>
    <GoogleMap :mapId="useRuntimeConfig().public.apiGoogleMapId" :api-promise="apiPromise"
      :style="`width: 100%; height:${mapheight}`" v-show="!isMobile || (isMobile && showGoogleMap)" ref="mapRef"
      :center="{
      lat: mapCenter.lat ?? 0,
      lng: mapCenter.lng ?? 0,
    }" :zoom="mapZoom" :scrollwheel="false" :streetViewControl="false" :disableDefaultUI="false"
      :mapTypeControl="false" :fullscreenControl="false" :zoomControl="true" gestureHandling="cooperative"
      zoomControlPosition="LEFT_CENTER" :panControl="true">
      <MarkerCluster v-if="markerClusterSettings.showCluster"
        :options="{ renderer: markerIcon, algorithm: clusterOptions }">
        <Marker :options="{ icon: pinIcon, position: location }" v-for="(location, i) in locations" :key="i"
          @click="onWindowClick(location)"></Marker>
      </MarkerCluster>

      <Marker v-else :options="{ icon: pinIcon, position: location }" v-for="(location, i) in locations" :key="i"
        @click="onWindowClick(location)"></Marker>

    </GoogleMap>
  </div>
</template>

<script setup>
import clusterIcon from "@/assets/icons/cluster.svg";
import pinIcon from "@/assets/icons/marker-pin.svg";
import { useGlobalStore } from "@/stores/global";
import { useMapStore } from "@/stores/map";
import { Loader } from "@googlemaps/js-api-loader";
import { SuperClusterAlgorithm } from "@googlemaps/markerclusterer";
import { GoogleMap, Marker, MarkerCluster } from "vue3-google-map";
import { useCheckoutStore } from "~/stores/checkout";
import { useGetStartedModalStore } from "~/stores/get-started-modal";
import { useProductStore } from "~~/stores/filter";

const globalStore = useGlobalStore();
const mapStore = useMapStore();
const getStartedStore = useGetStartedModalStore();
const productStore = useProductStore();
const checkoutStore = useCheckoutStore();
const emit = defineEmits(["getClinicsList"]);
const mapRef = ref(null);
const locations = ref([]);
const mapCenter = ref({
  lat: 0,
  lng: 0,
});
const country = ref(globalStore.siteCountry);
const showInfoWindow = ref(false);
const InfoWindowContent = ref("");
const currentInfoWindow = ref();
const locale = computed(() => globalStore.siteCurrentLocale);
const markerClusterSettings = computed(() => globalStore.siteClusterSettings);
const route = useRoute();

const clusterOptions = new SuperClusterAlgorithm({ maxZoom: markerClusterSettings.value.maxClusterZoom });
const { t } = useI18n();
const loader = new Loader({
  apiKey: useRuntimeConfig().public.apiGoogleMapKey,
  version: "weekly",
  libraries: ["places"],
});

const apiPromise = loader.load();
const props = defineProps({
  city: {
    type: String,
    default: "",
  },
  range: {
    type: Number,
  },
  zoom: {
    type: Number,
  },
  onSearchShowAllCity: {
    type: Boolean,
    default: false,
  },
  isMobile: {
    type: Boolean,
  },
  showGoogleMap: {
    type: Boolean,
  },
  testId: {
    type: Number,
  },
  mapheight: {
    type: String,
    default: "844px",
  },
  citySlug: {
    type: String,
  },
  cityTitle: {
    type: String,
  },
  categoryId: {
    default: "",
  },
  serviceType: {
    type: String,
    default: "",
  },
  consultationClinic: {
    type: Boolean,
    default: false,
  },
});

const markerIcon = {
  render({ count, position }) {
    return new mapRef.value.api.Marker({
      label: {
        text: String(count),
        color: "white",
        fontSize: "16px",
      },
      icon: {
        url: clusterIcon,
        scaledSize: new google.maps.Size(40, 40),
      },
      position,
      zIndex: Number(mapRef.value.api.Marker.MAX_ZINDEX) + count,
    });
  },
};

function onWindowClick(location) {
  if (currentInfoWindow.value != null) {
    currentInfoWindow.value.close();
  }

  const contentString =
    `<div class="flex flex-col items-start bg-secondary-lighter relative mt-2  mx-[18px]">
	         <button onclick="closeModal()" type="button"
	           class="absolute top-0 -right-2 text-gray-400 bg-light-silver rounded-full fill-secondary  p-0.5 ml-auto inline-flex ">
	           <svg aria-hidden="true" class="w-5 h-5" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
	             <path fill-rule="evenodd"
	               d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
	               clip-rule="evenodd"></path>
	           </svg></button>
             <div class="flex items-start mt-2">
	         <img loading="lazy" src="/icons/clinic-icon.svg" alt=""/>
	         <div class="pl-3">
	           <h3 class="pr-6"> ${location?.title} </h3>
	           <p class="pr-3 text-blog-14-sm my-2">${location?.content} </p>
             <button  onclick="openPanel('${location?.title}', ${location?.id}, '${location?.slug}')" type="button" class="relative h-9 select-none flex my-3 gap-2
	                  flex-nowrap ml-auto rounded font-bold focus:outline-none
	                  justify-center items-center duration-500 transition-all ease-in-out bg-secondary text-white hover:bg-[#B73983] focus:bg-[#B73983]
	             disabled:pointer-events-none disabled:opacity-60 w-full">` +
    t("common.btnSelect") +
    `</button>
            </div>
          </div>
	          
	        </div>`;

  const infowindow = new mapRef.value.api.InfoWindow({
    content: contentString,
  });
  infowindow.setOptions({
    position: location,
    pixelOffset: new google.maps.Size(0, -30),
    maxWidth: 300,
  });
  infowindow.open({
    map: mapRef.value.map,
  });

  window.closeModal = () => {
    currentInfoWindow.value.close();
  };

  window.openPanel = async (title, id, slug) => {
    productStore.clearAllFilters();
    // const { data: productsList } = await useFetchClinicTests(id);
    // mapStore.getTests(productsList.value.data);
    const location = {
      id,
      title,
      slug,
    };
    const pathWithoutLocale = route.path
      .replace(`/${locale.value}`, "")
      .split("/")[1];

    switch (pathWithoutLocale) {
      case "get-started":
        await navigateTo({
          ...route,
          query: {
            ...route.query,
            slug,
          },
        });
        getStartedStore.navigate("show_tests");
        break;

      case "clinics":
        //mapStore.openPanel();
        navigateTo(
          localeUrlQuery({
            query: {
              ...route.query,
              slug: location.slug,
              ...(location.content ?? { content: location.content }),
            },
          })
        );
        break;

      default:
        checkoutStore.setSelectedClinicId(location.id);
        return navigateTo(
          localeUrlQuery({
      
            query: {
              ...route.query,
              slug: location.slug,
              ...(location.content ?? { content: location.content }),
            },
          })
        );
    }
  };

  currentInfoWindow.value = infowindow;
}

watch(
  () => mapStore.getSelectedClinic,
  (n) => {
    showInfoWindow.value = true;
    InfoWindowContent.value = n;
  },
  { deep: true }
);

const mapZoom = computed(() => {
  if (props.zoom) {
    return Number(props.zoom);
  } else if (
    props.city != "all" &&
    props.city != "clearSearch" &&
    !props.zoom
  ) {
    return Number(globalStore.siteSearchZoom);
  } else if (props.isMobile) {
    return Number(globalStore.siteDefaultZoomMobile);
  }
  return Number(globalStore.siteDefaultZoom);
});


function resetMapCenter() {
  mapCenter.value = {
    lat: globalStore.siteMapConfigLatLng.latitude,
    lng: globalStore.siteMapConfigLatLng.longitude,
  };
}

watch(
  () => mapRef.value?.ready,
  async (ready) => {
    let mapPositionValue = {};
    if (!ready) return;
    if (props.citySlug) {
      mapPositionValue = Object.assign(
        mapPositionValue,
        await updateMapPosition(props, mapRef)
      );
      await getClinicList(mapPositionValue);
    }
  }
);

watch(
  () => props.city,
  async (newData, oldData) => {
    if (newData === oldData) return;
    let mapPositionValue = {};
    resetMapCenter();
    switch (newData) {
      case "clearSearch":
        mapPositionValue = Object.assign(
          mapPositionValue,
          globalStore.siteMapConfigLatLng
        );
        break;
      default:
        if (
          ((props.onSearchShowAllCity && newData !== "") ||
            !props.onSearchShowAllCity) &&
          mapRef.value?.ready
        ) {
          mapPositionValue = Object.assign(
            mapPositionValue,
            await updateMapPosition(props, mapRef)
          );
          await getClinicList(mapPositionValue);
        }
        break;
    }
  }
);

if (props.city == "all") {
  let mapPositionValue = {};
  mapPositionValue = Object.assign(
    mapPositionValue,
    globalStore.siteMapConfigLatLng
  );
  await getClinicList(mapPositionValue);
}
// fetch clinic list api
const updateMapPosition = async (props, mapRef) => {
  const updatedValues = {};

  if (props.categoryId && props.serviceType) {
    updatedValues.category = props.categoryId;
    updatedValues.service_type = props.serviceType;
  }

  if (props.consultationClinic) {
    updatedValues.consultation_clinic = 1;
  }

  if (props.testId) {
    updatedValues["tests[]"] = props.testId;
  }

  if (props.city || props.citySlug) {
    const range = props.range || globalStore.siteSearchRange;
    updatedValues.latitude = 0;
    updatedValues.longitude = 0;
    updatedValues.range = range;

    let city = props.city ? props.city : props.citySlug;
    city = await findAndReplaceSearchTerm(city);

    const geoCoder = new mapRef.value.api.Geocoder();
    try {
      const result = await geoCoder.geocode({
        address: city + ", " + country.value,
      });

      if (result) {
        const { results } = result;
        const resultData = results[0].geometry.location;
        updatedValues.latitude = resultData.lat();
        updatedValues.longitude = resultData.lng();
      }
    } catch (err) {
      console.log(err.message);
    }
  }

  return updatedValues;
};

async function getClinicList(mapPositionValue) {
  if (mapPositionValue.latitude == 0 && mapPositionValue.longitude == 0) {
    resetMapCenter();
  } else {
    mapCenter.value = {
      lat: mapPositionValue.latitude,
      lng: mapPositionValue.longitude,
    };
  }

  const { data: clinicList } = await useClinicListsByCity(mapPositionValue);
  if (!clinicList.value) return getClinicList(mapPositionValue);

  const entries = clinicList.value?.data ?? [];
  let result = entries.map((entry) => {
    return {
      id: entry.id,
      title: entry.display_title,
      slug: entry.slug,
      content: entry.show_address === true ? entry.address : "",
      distance: entry.distance,
      lat: Number(entry.latitude),
      lng: Number(entry.longitude),
    };
  });
  if (
    !props.onSearchShowAllCity ||
    (props.onSearchShowAllCity &&
      (props.city == "all" || props.city == "clearSearch"))
  ) {
    locations.value = result;
  }

  if (result && result.length === 0) {
    emit("getClinicsList", { data: "empty" });
  } else {
    emit("getClinicsList", result);
  }
}

async function findAndReplaceSearchTerm(searchTerm) {
  let relocators = globalStore.siteSettings.relocators;
  for (let i = 0; i < relocators.length; i++) {
    if (relocators[i].city_name_search.includes(searchTerm.toLowerCase())) {
      searchTerm = relocators[i].city_name_replace;
    }
  }
  return searchTerm;
}

onMounted(() => {
  mapCenter.value = {
    lat: globalStore.siteMapConfigLatLng.latitude,
    lng: globalStore.siteMapConfigLatLng.longitude,
  };
  mapStore.mapLoaded += 1;
});
</script>

<style scoped>
:deep(.gm-style-iw) {
  background: #f7f7f7;
  box-shadow: 0px 1px 6px #00000066;
  border-radius: 5px;
  padding: 0px;

}

:deep(.gm-style-iw-chr) {
  display: none;
}

:deep(.gm-style-iw-d) {
  background: #f7f7f7;
  overflow: auto !important;
}

:deep(::-webkit-scrollbar-track) {
  background: white;
}

:deep(.marker-cluster) {
  background-color: rgba(218, 94, 94, 0.6);
}

:deep(.marker-cluster div) {
  background-color: rgba(226, 36, 36, 0.6);
}

:deep(.gm-style .gm-style-iw-tc::after) {
  background: #f7f7f7;
}

:deep(button.gm-ui-hover-effect) {
  color: rgb(156 163 175);
  padding: 0.375rem;
  background-color: rgb(216 216 216) !important;
  border-radius: 9999px;
  visibility: hidden;
}
</style>
