import { useGlobalStore } from "~/stores/global";

export const useBlogLocale = () => {
	const preferedLocale = useGlobalStore().siteCurrentLocale;
	if (!preferedLocale) {
		return useState('blogLocale', () => 'en');
	}

	const localePrefix = preferedLocale.split('-').at(0);
	if (!localePrefix) {
		return useState('blogLocale', () => 'en');
	}

	return useState('blogLocale', () => localePrefix);
}

