import { defineStore } from "pinia";
// main is the name of the store. It is unique across your application
// and will appear in devtools
export const useMapStore = defineStore("map", {
    // a function that returns a fresh state
    state: () => ({
        selectedClinic: {
            id:'',
            title:'',
            slug: ''
        },
        showPanel: false,
        panelTests: [], // panel test data fetched based on clinic id
        test_id: null,
        clinic_id: null,
        mapLoaded: 0,
    }),
    // optional getters
    getters: {
        // get selected clinic
        getSelectedClinic: (state) => state.selectedClinic,
        getSelectedTestId: (state) => state.test_id,
    },
    // optional actions
    actions: {
        setSelectedClinic(data) {
            this.selectedClinic = data;
        },
        setTestId(value) {
            this.test_id = value;
        },
        setClinicId(value) {
            this.clinic_id = value;
        },

        reset() {
            this.selectedClinic = {};
            this.showPanel = false;
        },
        openPanel() {
            this.showPanel = true;
        },
        closePanel() {
            this.showPanel = false;
        },
        getTests(data) {
            this.panelTests = data;
        },
    },
    persist: true,
});