<template>
  <div> 
    <slot></slot>
    <ProductFilterItem
      :name="`${$t('common.category')}`"
      :is-active="isActive"
      index-of="category"
      :items="contentFilters.categories"
      v-if="contentFilters.categories.length > 1"
    />

    <ProductFilterItem
      :name="`${$t('common.services')}`"
      :is-active="isActive"
      index-of="service"
      :items="contentFilters.service_types"
      v-if="contentFilters.service_types.length > 1"
    />

    <ProductFilterItem
      :name="`${$t('filter.testsFor')}`"
      :is-active="isActive"
      index-of="infection"
      :items="contentFilters.infections"
      v-if="contentFilters.infections.length > 1"
    /> 

    <ProductFilterItem
      :name="`${$t('filter.testType')}`"
      :is-active="isActive"
      index-of="test_type"
      :items="contentFilters.test_types"
      v-if="contentFilters.test_types.length > 1"
    />
    
    <ProductFilterItem
      :name="`${$t('filter.collectionMethod')}`"
      :is-active="isActive"
      index-of="collection"
      :items="contentFilters.collection_methods"
      v-if="contentFilters.collection_methods.length > 1"
    />

   
    <ProductFilterRangePrice
      :min="contentFilters.min_price"
      :max="contentFilters.max_price"
    />
  </div>
</template>
<script setup>
const route = useRoute();
const props = defineProps({
  contentFilters: Object,
  isActive: Boolean
});
</script>
