<template>
  <div class="w-fit mx-auto">
    <CommonSpinner size="3rem" border-weight="3px" />
  </div>
</template>

<script setup lang="ts">
const route = useRoute();
const getStartedModalStore = useGetStartedModalStore();
const mapStore = useMapStore();

/**
 * Selected service entity.
 */
const selectedService = computed(() => getStartedModalStore.selectedService);

/**
 * Get clinic slug from route query params.
 */
const selectedClinicSlug = computed(() => route.query.slug);

/**
 * Fetch all tests related to a specific clinic.
 * @param id - Clinic Id from where we should fetch tests.
 */
async function fetchClinicTests(id: string) {
  const { data: productsList } = await useFetchClinicTests(id);
  mapStore.getTests(productsList.value.data);
}

/**
 * Fetch clinic detail by selected slug.
 */
async function fetchClinicDetailBySlug() {
  if (!selectedClinicSlug.value) return;

  const { data: clinicDetail } = await useFetchClinicDetailBySlug(
    selectedClinicSlug.value
  );
  const clinic = clinicDetail.value.data;
  if (!clinic) return;

  mapStore.setSelectedClinic({
    id: clinic.id,
    title: clinic.display_title,
    slug: clinic.slug,
  });
  await fetchClinicTests(clinic.id);
}

/**
 * Watch selected clinic slug change in order to update the products.
 */
watch(selectedClinicSlug, fetchClinicDetailBySlug);
await fetchClinicDetailBySlug();

/**
 * Open modal with selected service and clinic view.
 */
function openModal() {
  getStartedModalStore.navigate("select_clinic");
  getStartedModalStore.open({
    title: "",
    slug: "",
    clinic_view: "map",
    ...selectedService.value,
    content: "",
  });
}

/**
 * Abort navigation and reset service param.
 */
async function abort() {
  navigateTo({
    params: {
      ...route.params,
      service: "",
    },
  });
}

/**
 * Watch for modal open state changes to trigger abort if closed.
 */
watch(
  () => getStartedModalStore.isOpen,
  (isOpen, wasOpen) => {
    if (wasOpen && !isOpen) abort();
  }
);

/**
 * Oen/Close modal on component mount/unmount.
 */
onBeforeUnmount(getStartedModalStore.close);
onBeforeMount(openModal);
</script>
